import axios from "axios";
import {
  API_BASE_URL,
  TS_API_BASE_URL,
  APP_BASE_URL,
} from "../config/constants";
import { firebaseCloudMessaging } from "../util/webPush";
export default class UserService {
  static getCookies() {
    const cookies = {};
    document.cookie.split(";").forEach((cookie) => {
      const [key, value] = cookie.split("=");

      cookies[key.trim()] = decodeURIComponent(value);
    });

    return cookies;
  }

  static getAuthToken() {
    const cookies = this.getCookies();
    return cookies.d5_session;
  }

  static AcceptLGPDTerms() {
    let domain = `domain=${APP_BASE_URL.replace("https://", ".")};`;
    if (domain.includes("localhost")) {
      domain = "";
    }
    document.cookie = `AcceptedTerms=true; ${domain} Path=/; Expires=Mon, 01 Jan 2080-01-01 00:00:00 GMT;`;
  }

  static login(email, password) {
    const url = `${API_BASE_URL}/`;

    return axios.post(url, {
      email,
      password,
    });
  }

  static register(email, fullName, password, confirmPassword) {
    const url = `${API_BASE_URL}/`;

    return axios.post(url, {
      email,
      fullName,
      password,
      confirmPassword,
    });
  }

  static getCurrentUser() {
    const url = `${API_BASE_URL}/users/current`;

    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${this.getAuthToken()}`,
      },
    });
  }

  static addNotificationToken(data) {
    const url = `${TS_API_BASE_URL}/notifications/token`;

    return axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${this.getAuthToken()}`,
      },
    });
  }

  static getUserFavorites() {
    const url = `${API_BASE_URL}/users/favorites`;

    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${this.getAuthToken()}`,
      },
    });
  }

  static getUserDevices() {
    const url = `${TS_API_BASE_URL}/notifications/token`;

    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${this.getAuthToken()}`,
      },
    });
  }

  static addToken(token) {
    const url = `${TS_API_BASE_URL}/notifications/token`;

    return axios.post(url, {
      headers: {
        Authorization: `Bearer ${this.getAuthToken()}`,
      },
      data: {
        token,
      },
    });
  }

  static removeToken(token) {
    const url = `${TS_API_BASE_URL}/notifications/token/${token}`;

    return axios.delete(url, {
      headers: {
        Authorization: `Bearer ${this.getAuthToken()}`,
      },
    });
  }

  static async updateUserFavorites(teams, tournaments, matches, status = false) {
    try {
      const url = `${TS_API_BASE_URL}/notifications/token`;
      const headers = { Authorization: `Bearer ${this.getAuthToken()}` };
      const data = { status };

      if (teams.length > 0) data.topic = `team${teams[0].teamId}`;
      if (tournaments.length > 0)
        data.topic = `tournament${tournaments[0].tournamentId}`;
      if (matches && matches.length > 0)
        data.topic = `match${matches[0].matchId}`;

      data.token = await firebaseCloudMessaging.tokenInlocalforage()
      return await axios.put(url, data, { headers });
    } catch (err) {
      console.log('[UserService] updateUserFavorites:', err.message);
    }

    return {};
  }

  static updateUserData(
    email,
    fullName,
    displayName,
    newsletterEnabled,
    hasOnboarded
  ) {
    const url = `${API_BASE_URL}/users`;

    const data = {
      email,
      fullName,
      displayName,
      newsletterEnabled,
      hasOnboarded,
    };

    return axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${this.getAuthToken()}`,
      },
    });
  }

  static updateSteamAvatar() {
    const url = `${API_BASE_URL}/users/avatar`;

    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${this.getAuthToken()}`,
      },
    });
  }

  static async callGCIDLogin() {
    const url = `${API_BASE_URL}/auth/gcid/login`;
    window.location.replace(url);
  }

  static async callGCIDLogout() {
    const url = `${API_BASE_URL}/auth/gcid/logout`;

    return axios.get(url, {
      headers: {
        Authorization: `Bearer ${this.getAuthToken()}`,
      },
    });
  }

  static async logout() {

    try {
      await this.callGCIDLogout()
    } catch (error) {
      console.error("Error on callGCIDLogout:", error.message)
    } finally {
      this.clearCookieByName("d5_session")
      this.clearCookieByName("gcmedia-gcid")
      window.location.replace(APP_BASE_URL);
    }
    // Call ts-api gcid/logout
  }

  static clearCookieByName(cookieName) {
    let domain = `domain=${APP_BASE_URL.replace("https://", ".")};`;
    if (domain.includes("localhost")) {
      domain = "";
    }
    document.cookie = `${cookieName}=; ${domain} Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;

  }
}
