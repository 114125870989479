import React from "react";

const CaretDown = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" version="1.1">
      <path d="M7,10L12,15L17,10H7Z" />
    </svg>
  );
};

export default CaretDown;
