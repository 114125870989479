import styled from "styled-components";
import { colors } from "../../config/styles";

const HeaderAMP = () => (
  <AmpHeader>
    <a href="https://draft5.gg">
      <amp-img
        layout="fixed"
        src="https://static.draft5.gg/assets/logo.png"
        width="78"
        height="30"
        alt=""
      />
    </a>
  </AmpHeader>
);

export const config = { amp: true };

export default HeaderAMP;

const AmpHeader = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  height: 50px;
  background: ${colors.MAIN};
  display: flex;
  align-items: center;
  justify-content: center;

  & + div {
    margin-top: 50px;
  }
`;
