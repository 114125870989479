import React from "react";

const Blank = (props) => {
  return (
    <svg {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
      <path d=""></path>
      </g>
      <defs>
        <clipPath id="clip0_292_3156">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Blank;