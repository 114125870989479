import React, { Component } from "react";
import UserService from "../services/user";
import { toast } from "react-toastify";
import { firebaseCloudMessaging } from "../util/webPush";

const UserContext = React.createContext();

export default class UserContextProvider extends Component {
  state = {
    isLoggedIn: false,
    userData: {
      userId: null,
      steamId: "",
      fullName: "",
      email: "",
      createdAt: "",
      displayName: "",
      newsletterEnabled: false,
      hasOnboarded: true,
    },
    devices: [],
    favorites: {
      teams: [],
      tournaments: [],
      matches: [],
    },
    ui: {
      updateUserData: {
        isLoading: false,
        error: null,
      },
      steamAvatar: {
        isLoading: false,
      },
      favorites: {
        isLoading: false,
      },
    },
  };

  componentDidMount() {
    const token = UserService.getAuthToken();
    if (token) {
      const userData = UserService.getCurrentUser();
      const userFavorites = UserService.getUserFavorites();
      const userDevices = UserService.getUserDevices();
      Promise.all([userData, userFavorites, userDevices])
        .then(([userDataResponse, favoritesResponse, userDevicesResponse]) => {
          this.setState({
            userData: userDataResponse.data.data,
            favorites: favoritesResponse.data.data,
            devices: userDevicesResponse.data.data,
            isLoggedIn: true,
          });
        })
        .catch(() => {
          this.setState({
            isLoggedIn: false,
          });
        });
    }
  }

  getCurrentUser = () => {
    UserService.getCurrentUser()
      .then((response) => {
        this.setState({
          userData: response.data.data,
          isLoggedIn: true,
        });
      })
      .catch(() => {
        this.setState({
          isLoggedIn: false,
        });
      });
  };

  updateUserData = (values, hasOnboarded = true) => {
    this.setState({
      state: {
        ...this.state.state,
        updateUserData: {
          isLoading: true,
          error: null,
        },
      },
    });

    UserService.updateUserData(
      values.email,
      values.fullName,
      values.displayName,
      values.newsletterEnabled,
      hasOnboarded
    )
      .then(() => {
        this.setState({
          userData: {
            ...this.state.userData,
            email: values.email,
            fullName: values.fullName,
            displayName: values.displayName,
            newsletterEnabled: values.newsletterEnabled,
          },
          ui: {
            ...this.state.ui,
            updateUserData: {
              isLoading: false,
              error: null,
            },
          },
        });
        toast.success("Perfil atualizado com sucesso");
      })
      .catch(() => {
        this.setState({
          ui: {
            ...this.state.ui,
            updateUserData: {
              isLoading: false,
              error: "Ocorreu um erro ao processar sua requisição",
            },
          },
        });
        toast.error("Ocorreu um erro ao processar sua requisição");
      });
  };

  updateSteamAvatar = () => {
    this.setState({
      ui: {
        ...this.state.ui,
        steamAvatar: {
          isLoading: true,
        },
      },
    });

    UserService.updateSteamAvatar()
      .then(() => {
        this.setState({
          ui: {
            ...this.state.ui,
            steamAvatar: {
              isLoading: false,
            },
          },
        });

        toast.success("Avatar atualizado com sucesso");
        this.getCurrentUser();
      })
      .catch((e) => {
        toast.error(
          "Ocorreu um erro ao atualizar o avatar. Por favor tente novamente."
        );
        this.setState({
          ui: {
            ...this.state.ui,
            steamAvatar: {
              isLoading: false,
            },
          },
        });
      });
  };

  updateUserFavorites = () => {
    UserService.getUserFavorites().then((response) => {
      this.setState({
        favorites: response.data.data,
      });
    });
  };

  deleteFromFavorites = (
    deletedTeams,
    deletedTournaments,
    deletedMatches = []
  ) => {
    this.setState({
      ui: {
        ...this.state.ui,
        favorites: {
          isLoading: true,
        },
      },
    });

    UserService.updateUserFavorites(
      deletedTeams,
      deletedTournaments,
      deletedMatches
    )
      .then(() => {
        this.setState({
          ui: {
            ...this.state.ui,
            favorites: {
              isLoading: false,
            },
          },
          favorites: {
            ...this.state.favorites,
            teams: this.state.favorites.teams.filter((team) => {
              return (
                deletedTeams
                  .map((deletedTeam) => deletedTeam.teamId)
                  .indexOf(team.teamId) === -1
              );
            }),
            tournaments: this.state.favorites.tournaments.filter(
              (tournament) => {
                return (
                  deletedTournaments
                    .map((deletedTournament) => deletedTournament.tournamentId)
                    .indexOf(tournament.tournamentId) === -1
                );
              }
            ),
            matches: this.state.favorites.matches.filter((match) => {
              return (
                deletedMatches
                  .map((deletedMatch) => deletedMatch.matchId)
                  .indexOf(match.matchId) === -1
              );
            }),
          },
        });

        toast.info("Favorito removido com sucesso.");
      })
      .catch(() => {
        toast.error(
          "Ocorreu um erro ao processar sua requisição. Por favor tente novamente"
        );
      });
  };

  addToFavorites = (teams = [], tournaments = [], matches = []) => {
    this.setState({
      ui: {
        ...this.state.ui,
        favorites: {
          isLoading: true,
        },
      },
    });

    UserService.updateUserFavorites(teams, tournaments, matches, true)
      .then(() => {
        const { favorites } = this.state;

        const favoriteTeams = teams
          ? [...favorites.teams, ...teams]
          : favorites.teams;

        const favoriteTournaments = tournaments
          ? [...favorites.tournaments, ...tournaments]
          : favorites.tournaments;

        const favoriteMatches = matches
          ? [...favorites.matches, ...matches]
          : favorites.matches;

        this.setState({
          ui: {
            ...this.state.ui,
            favorites: {
              isLoading: false,
            },
          },
          favorites: {
            ...favorites,
            teams: favoriteTeams,
            tournaments: favoriteTournaments,
            matches: favoriteMatches,
          },
        });

        toast.info("Favorito adicionado com sucesso.");
      })
      .catch((error) => {
        console.log("[UserContext] updateUserFavorites error:", error.message);
        toast.error(
          "Ocorreu um erro ao processar sua requisição. Por favor tente novamente"
        );
        this.setState({
          ui: {
            ...this.state.ui,
            favorites: {
              isLoading: false,
            },
          },
        });
      });
  };

  addToken = async () => {
    const devices = [...this.state.devices];

    const token = await firebaseCloudMessaging.getToken();

    const hasSavedToken = devices.filter((device) => device.token === token);

    if (hasSavedToken.length === 0 && token) {
      const deviceData = await firebaseCloudMessaging.sendToken(token);
      if (deviceData) {
        const devices = [...this.state.devices, deviceData];
        this.setState({ devices });
      }
    }
  };

  removeToken = async (token) => {
    try {
      await UserService.removeToken(token);
      const filteredDevices = this.state.devices.filter(
        (device) => device.token != token
      );

      this.setState({
        devices: filteredDevices,
      });
      toast.success("Notificações desabilitadas com sucesso");
    } catch (error) {
      toast.error("Ocorreu um erro ao remover seu navegador.");
    }
  };

  logout = () => {
    UserService.logout();
  };

  render() {
    return (
      <UserContext.Provider
        value={{
          ...this.state,
          updateUserFavorites: this.updateUserFavorites,
          updateUserData: this.updateUserData,
          updateSteamAvatar: this.updateSteamAvatar,
          deleteFromFavorites: this.deleteFromFavorites,
          addToFavorites: this.addToFavorites,
          addToken: this.addToken,
          removeToken: this.removeToken,
          logout: this.logout,
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

const UserContextConsumer = UserContext.Consumer;
export { UserContext, UserContextConsumer };
