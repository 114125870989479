import React from "react";

const CircleArrowDown = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M14.1667 6.66667C14.1667 8.43478 13.4643 10.1305 12.2141 11.3807C10.9638 12.631 9.26812 13.3333 7.50001 13.3333C6.62453 13.3333 5.75762 13.1609 4.94879 12.8259C4.13995 12.4908 3.40502 11.9998 2.78596 11.3807C1.53572 10.1305 0.833344 8.43478 0.833344 6.66667C0.833344 4.89856 1.53572 3.20286 2.78596 1.95262C4.03621 0.702379 5.7319 0 7.50001 0C8.37549 0 9.2424 0.172438 10.0512 0.50747C10.8601 0.842501 11.595 1.33356 12.2141 1.95262C12.8331 2.57168 13.3242 3.30661 13.6592 4.11544C13.9942 4.92428 14.1667 5.79119 14.1667 6.66667ZM12.8333 6.66667C12.8333 5.25218 12.2714 3.89562 11.2712 2.89543C10.2711 1.89524 8.9145 1.33333 7.50001 1.33333C6.08552 1.33333 4.72897 1.89524 3.72877 2.89543C2.72858 3.89562 2.16668 5.25218 2.16668 6.66667C2.16668 8.08115 2.72858 9.43771 3.72877 10.4379C4.72897 11.4381 6.08552 12 7.50001 12C8.9145 12 10.2711 11.4381 11.2712 10.4379C12.2714 9.43771 12.8333 8.08115 12.8333 6.66667ZM3.50001 5.33333L7.50001 9.33333L11.5 5.33333L10.5667 4.4L7.50001 7.46667L4.43334 4.4L3.50001 5.33333Z" fill="#545D65" />
    </svg>
  );
};

export default CircleArrowDown;
