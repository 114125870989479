import { useEffect, Fragment } from "react";

// slick carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// toastify
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";

import "./app.css";

// draft lib
import "draft5-post-content-renderer/dist/index.css";

// Dayjs
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import AdvancedFormat from "dayjs/plugin/advancedFormat";
import CustomParseFormat from "dayjs/plugin/customParseFormat";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(AdvancedFormat);
dayjs.extend(CustomParseFormat);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.locale("pt-br");

// fontaweome
import "font-awesome/css/font-awesome.min.css";

// context
import UserContextProvider from "../contexts/UserContext";
import { useAmp } from "next/amp";
import Head from "next/head";
import HeaderAMP from "../components/AMP/HeaderAMP";
import { Header } from "../components/Shared/Header";
import { BaseCSS } from "styled-bootstrap-grid";
import HeadScripts from "../components/Shared/HeadScripts";
import { firebaseCloudMessaging } from "../util/webPush";
import AdContextProvider from "../contexts/AdContext";
import { FeatureFlagProvider } from "../contexts/FeatureFlagContext";
import { useRouter } from "next/router";
import { BannerCTA } from "../components/Major23/BannerCTA";

function MyApp({ Component, pageProps }) {
  const isAmp = useAmp();
  const router = useRouter();
  useEffect(() => {
    setToken();
    async function setToken() {
      try {
        await firebaseCloudMessaging.init();
      } catch (error) {
        console.error("Error on Firebase Cloud Messaging init:", error.message);
      }
    }
  }, []);

  if (isAmp) {
    return (
      <Fragment>
        <HeaderAMP />
        <BaseCSS />
        <Component {...pageProps} />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Head>
        <HeadScripts />
      </Head>

      <FeatureFlagProvider>
        <AdContextProvider>
          <UserContextProvider>
            {/* {!(router.pathname === "/major" || router.pathname === "/iemrio" || router.pathname.startsWith("/major/") || router.pathname.startsWith("/iemrio/")) && (
              <Header />
            )} */}
            <BaseCSS />
            <ToastContainer position="bottom-right" />
            <Component {...pageProps} />
          </UserContextProvider>
        </AdContextProvider>
      </FeatureFlagProvider>
    </Fragment>
  );
}

export default MyApp;
