import React from "react";

const CaretRight = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" version="1.1">
      <path d="M10,17L15,12L10,7V17Z" />
    </svg>
  );
};

export default CaretRight;
