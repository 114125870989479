import React from "react";

const Calendar = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M12.8333 1.99996H12.1667V0.666626H10.8333V1.99996H4.16668V0.666626H2.83334V1.99996H2.16668C1.43334 1.99996 0.833344 2.59996 0.833344 3.33329V14C0.833344 14.7333 1.43334 15.3333 2.16668 15.3333H12.8333C13.5667 15.3333 14.1667 14.7333 14.1667 14V3.33329C14.1667 2.59996 13.5667 1.99996 12.8333 1.99996ZM12.8333 14H2.16668V5.33329H12.8333V14Z" fill="#D5D5D5" />
    </svg>

  );
};

export default Calendar;
