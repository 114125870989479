import React from "react";

const Trophy = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M11.5 0C10.9 0 10.1666 0.666667 10.1666 1.33333H4.83331C4.83331 0.666667 4.09998 0 3.49998 0H0.833313V6C0.833313 6.66667 1.49998 7.33333 2.16665 7.33333H3.63331C3.89998 8.66667 4.76665 9.8 6.83331 10V11.3867C4.83331 11.6933 4.83331 13.3333 4.83331 13.3333H10.1666C10.1666 13.3333 10.1666 11.6933 8.16665 11.3867V10C10.2333 9.8 11.1 8.66667 11.3666 7.33333H12.8333C13.5 7.33333 14.1666 6.66667 14.1666 6V0H11.5ZM3.49998 6H2.16665V1.33333H3.49998V6ZM12.8333 6H11.5V1.33333H12.8333V6Z" fill="#D5D5D5" />
    </svg>
  );
};

export default Trophy;
