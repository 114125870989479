import { API_BASE_URL } from "../config/constants";
import axios from "axios";

export default class AdsService {
  static getSpots() {
    const url = `${API_BASE_URL}/campaigns/spots`;

    return axios.get(url);
  }

  static getCampaigns() {
    const url = `${API_BASE_URL}/campaigns`;

    return axios.get(url);
  }
}
