import React from "react";

const Circle = (props) => {
  return (
    <svg version="1.1" {...props}>
      <path d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
    </svg>
  );
};

export default Circle;
