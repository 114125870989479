import firebase from "firebase/compat/app";
import { getMessaging, getToken } from "firebase/messaging";
import localforage from "localforage";
import { FIREBASE_KEYS, FIREBASE_VAPID_KEY } from "../config/constants";
import UserService from "../services/user";
import { toast } from "react-toastify";

const THREE_WEEKS_MILI = 3 * 7 * 24 * 60 * 60 * 1000

const TOKEN_CREATED_AT_KEY = 'token_created_at';
const FCM_TOKEN_KEY = 'fcm_token';

const firebaseCloudMessaging = {
  tokenInlocalforage: () => {
    return localforage.getItem(FCM_TOKEN_KEY);
  },

  sendToken: async (token) => {
    try {
      const channel = firebaseCloudMessaging.getBrowserInfo();
      const device = navigator.platform;
      const data = {
        token,
        channel,
        device,
      };

      await UserService.addNotificationToken(data);

      if (window.location.search !== "?auth")
        toast.success("Notificações habilitadas com sucesso");

      localforage.setItem(FCM_TOKEN_KEY, token);
      localforage.setItem(TOKEN_CREATED_AT_KEY, Date.now());

      return data;
    } catch (error) {
      toast.error(
        "Ocorreu um erro ao registrar seu navegador. Por favor, reinicie as permissões de notificação e tente novamente."
      );
    }
  },

  isCreatedTokenDateValid: async ()=> {
    const createdTokenDate =  await localforage.getItem(TOKEN_CREATED_AT_KEY);
    if(! createdTokenDate) return false;

    return +createdTokenDate + THREE_WEEKS_MILI > Date.now();
  },

  getToken: async () => {
    try {
      const tokenInLocalForage =
        await firebaseCloudMessaging.tokenInlocalforage();

      if (tokenInLocalForage !== null && window.location.search !== "?auth") {
        if(await firebaseCloudMessaging.isCreatedTokenDateValid()){
          return tokenInLocalForage;
        }
      }
      const messaging = getMessaging();
      const fcm_token = await getToken(messaging, {
        vapidKey: FIREBASE_VAPID_KEY,
      });
      if (fcm_token) {
        await firebaseCloudMessaging.sendToken(fcm_token);
        return fcm_token;
      }
    } catch (error) {
      console.log("firebaseCloudMessaging getToken error:", error.message);
      return null;
    }
  },

  init: async function () {
    try {
      await firebase.initializeApp(FIREBASE_KEYS);
      // Query for notification permission
      // ==========================================
      navigator.permissions
        .query({ name: "notifications" })
        .then((permissionStatus) => {
          if (permissionStatus.state !== "granted") {
            Notification.requestPermission();
          } else if (window.location.search !== "?auth") {
            firebaseCloudMessaging.getToken();
          }

          permissionStatus.addEventListener("change", function () {
            if (this.state === "granted") {
              firebaseCloudMessaging.getToken();
            }
          });
        });
    } catch (error) {
      return null;
    }
  },

  getBrowserInfo() {
    var isOpera = !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0;

    var isFirefox = typeof InstallTrigger !== "undefined";
    var isSafari =
      Object.prototype.toString
        .call(window.HTMLElement)
        .indexOf("Constructor") > 0;

    var isChrome = !!window.chrome && !isOpera;
    var isIE = !!document.documentMode;
    if (isOpera) {
      return "opera";
    } else if (isFirefox) {
      return "firefox";
    } else if (isChrome) {
      return "chrome";
    } else if (isSafari) {
      return "safari";
    } else if (isIE) {
      return "explorer";
    } else {
      return "outro";
    }
  },
};
export { firebaseCloudMessaging };
