import React from "react";

const CloseModal = (props) => {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 1.25C5.16797 1.25 1.25 5.16797 1.25 10C1.25 14.832 5.16797 18.75 10 18.75C14.832 18.75 18.75 14.832 18.75 10C18.75 5.16797 14.832 1.25 10 1.25ZM10 17.2656C5.98828 17.2656 2.73438 14.0117 2.73438 10C2.73438 5.98828 5.98828 2.73438 10 2.73438C14.0117 2.73438 17.2656 5.98828 17.2656 10C17.2656 14.0117 14.0117 17.2656 10 17.2656Z" fill="#262F37" />
      <path d="M10 2.73438C5.98828 2.73438 2.73438 5.98828 2.73438 10C2.73438 14.0117 5.98828 17.2656 10 17.2656C14.0117 17.2656 17.2656 14.0117 17.2656 10C17.2656 5.98828 14.0117 2.73438 10 2.73438ZM13.3555 13.0293C13.3789 13.0586 13.3926 13.0938 13.3926 13.1309C13.3926 13.2188 13.3223 13.2871 13.2363 13.2871L11.9473 13.2813L10.0078 10.9688L8.06836 13.2832L6.77734 13.2891C6.69141 13.2891 6.62109 13.2188 6.62109 13.1328C6.62109 13.0957 6.63477 13.0605 6.6582 13.0313L9.19922 10.0059L6.6582 6.97852C6.6346 6.94989 6.62151 6.91405 6.62109 6.87695C6.62109 6.78906 6.69141 6.7207 6.77734 6.7207L8.06836 6.72656L10.0078 9.03906L11.9492 6.72461L13.2383 6.71875C13.3242 6.71875 13.3945 6.78906 13.3945 6.875C13.3945 6.91211 13.3828 6.94922 13.3594 6.97656L10.8184 10.0039L13.3555 13.0293Z" fill="white" fill-opacity="0.69" />
      <path d="M13.3945 6.875C13.3945 6.78906 13.3242 6.71875 13.2383 6.71875L11.9492 6.72461L10.0078 9.03906L8.06836 6.72656L6.77734 6.7207C6.69141 6.7207 6.62109 6.78906 6.62109 6.87695C6.62109 6.91406 6.63477 6.94922 6.6582 6.97852L9.19922 10.0059L6.6582 13.0313C6.6346 13.0599 6.62151 13.0957 6.62109 13.1328C6.62109 13.2188 6.69141 13.2891 6.77734 13.2891L8.06836 13.2832L10.0078 10.9687L11.9473 13.2812L13.2363 13.2871C13.3223 13.2871 13.3926 13.2187 13.3926 13.1309C13.3926 13.0937 13.3789 13.0586 13.3555 13.0293L10.8184 10.0039L13.3594 6.97656C13.3828 6.94922 13.3945 6.91211 13.3945 6.875Z" fill="#262F37" />
    </svg>

  );
};

export default CloseModal;
