import React from "react";

const Bars = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" {...props}>
      <path d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" />
    </svg>
  );
};

export default Bars;
