import React, { useEffect } from "react";
import FeatureFlagsService from "../services/feature-flags";

export const FeatureFlagContext = React.createContext(null);

export const FeatureFlagProvider = (props) => {
  const [featureFlags, setFeatureFlags] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    FeatureFlagsService.getFeatureFlags().then((response) => {
      setFeatureFlags(response.data.data);
      setIsLoading(false);
    });
  }, []);

  const getFlagValue = (flagName) => {
    if (featureFlags.length === 0) return null;

    const flag = featureFlags.find((flag) => flag.name === flagName);

    if (!flag) return null;

    return flag.value;
  };

  return (
    <FeatureFlagContext.Provider
      value={{ flags: featureFlags, getFlagValue, isLoading }}
    >
      {props.children}
    </FeatureFlagContext.Provider>
  );
};
