import React from "react";

const List = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" {...props}>
			<path d="M464.844,0H35.156C15.744,0,0,15.74,0,35.156v429.688C0,484.26,15.744,500,35.156,500h429.688
			C484.26,500,500,484.26,500,464.844V35.156C500,15.74,484.26,0,464.844,0z M122.14,397.624c-19.392,0-35.164-15.776-35.164-35.172
			c0-19.388,15.772-35.164,35.164-35.164c19.392,0,35.172,15.776,35.172,35.164C157.312,381.848,141.532,397.624,122.14,397.624z
			 M122.14,292.156c-19.392,0-35.164-15.776-35.164-35.172c0-19.388,15.772-35.164,35.164-35.164
			c19.392,0,35.172,15.776,35.172,35.164C157.312,276.38,141.532,292.156,122.14,292.156z M122.14,186.688
			c-19.392,0-35.164-15.776-35.164-35.172c0-19.388,15.772-35.164,35.164-35.164c19.392,0,35.172,15.776,35.172,35.164
			C157.312,170.912,141.532,186.688,122.14,186.688z M419.324,375H215.96c-6.472,0-11.72-5.248-11.72-11.72
			c0-6.476,5.248-11.72,11.72-11.72h203.364c6.472,0,11.72,5.244,11.72,11.72C431.044,369.752,425.796,375,419.324,375z
			 M419.324,269.532H215.96c-6.472,0-11.72-5.248-11.72-11.72c0-6.476,5.248-11.72,11.72-11.72h203.364
			c6.472,0,11.72,5.244,11.72,11.72C431.044,264.28,425.796,269.532,419.324,269.532z M419.324,164.064H215.96
			c-6.472,0-11.72-5.248-11.72-11.72c0-6.476,5.248-11.72,11.72-11.72h203.364c6.472,0,11.72,5.244,11.72,11.72
			C431.044,158.812,425.796,164.064,419.324,164.064z"/>
		</svg>
	);
};

export default List;
