import React from "react";

const Clock = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 256 256">
      <path d="M128,20A108,108,0,1,0,236,128,108.12217,108.12217,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.0953,84.0953,0,0,1,128,212Zm68-84a12,12,0,0,1-12,12H128a12,12,0,0,1-12-12V72a12,12,0,0,1,24,0v44h44A12,12,0,0,1,196,128Z"></path>
</svg>
  );
};

export default Clock;
