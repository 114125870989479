import React from "react";

const TelevisionOff = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" {...props}>
      <path d="M0 0h24v24H0V0z" fill="none"></path><path d="M1 3.54l1.53 1.53C1.65 5.28 1 6.06 1 7v12c0 1.1.9 2 2 2h15.46l2 2 1.26-1.27L2.27 2.27 1 3.54zM3 19V7h1.46l12 12H3zM21 5h-7.58l3.29-3.3L16 1l-4 4-4-4-.7.7L10.58 5H7.52l2 2H21v11.48l1.65 1.65c.22-.32.35-.71.35-1.13V7c0-1.11-.89-2-2-2z"></path></svg>
  );
};

export default TelevisionOff;
