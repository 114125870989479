import { API_BASE_URL } from "../config/constants";
import axios from "axios";

export default class FeatureFlagsService {
  static getFeatureFlags() {
    const url = `${API_BASE_URL}/feature-flags`;

    return axios.get(url);
  }
}
