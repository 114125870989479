import React from "react";

const Check = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" version="1.1">
      <path d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" />
    </svg>
  );
};

export default Check;
