import React from "react";

const HamburguerMenu = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M0.5 12H18.5V10H0.5V12ZM0.5 7H18.5V5H0.5V7ZM0.5 0V2H18.5V0H0.5Z" fill="white" />
    </svg>

  );
};

export default HamburguerMenu;
