export const colors = {
  MAIN: "#171c21",
  MAIN_DARK: "#0d0f12",
  MAIN_DARKER: "#020203",
  MAIN_LIGHTER: "#262f37",

  ACCENT: "#0084ff",
  ACCENT_LIGHTER: "#80C1FF",
  ACCENT_LIGHTEST: "#DAEDFF",
  ACCENT_LIGHTEST_LIGHTER: "#e3f1ff",
  ACCENT_BRIGHTER: "#0069ff",

  GRAY_WHITE: "#f5f5f5",
  GRAY_LIGHTEST: "#f0f0f0",
  GRAY_LIGHTER: "#d5d5d5",
  GRAY_LIGHT: "#959595",
  GRAY: "#565656",
  GRAY_DARK: "#333",
  GRAY_DARKER: "#171717",

  YELLOW_WARNING: "#ffffc2",
  YELLOW_WARNING_LIGHT: "#fffff0",

  SUCCESS: "#18bc9c",
  SUCCESS_BRIGHTER: "#f9fcfb",
  SUCCESS_LIGHT: "#f5fffd",

  ERROR: "#e74c3c",
  ERROR_LIGHT: "#f8a69d",

  TWITCH: "#6441a5",
  YOUTUBE: "#e52d27",
  SMASHCAST: "#99CC00",
  TWITTER: "#33ccff",
  TWITTERX: "#000",
  FACEBOOK: "#337fff",
  WHATSAPP: "#00d95f",
  TELEGRAM: "#34aadf",
  BLUESKY: "#0084ff",
  THREADS: "#000",

  LIVE: "#d2322d",
  LIVE_LIGHT: "#fcf9f9",
  LIVE_LIGHTER: "#ffdada",
  LIVE_BRIGHTER: "#fff0f0",

  FINISHED: "#229e45",
  FINISHED_LIGHT: "#F9FCFA",

  VICTORY: "#5cb85c",
  DEFEAT: "#d2322d",
  DRAW: "#959595",

  FAVORITE: "#ffb600",

  ODDS_BETTER_MAIN: "#E28800",
  ODDS_BETTER_LIGHT: "#FFF9DA",

  // MAJOR_HUB
  MJH_BLUE: "#001A6D",
  MJH_BLUE_SECONDARY: "#001659",
  MJH_BLUE_LIGHT: "#223f9c",
  MJH_YELLOW: "#FFFF2E",
  MJH_YELLOW_LIGHT: "#fffe3e",
  MJH_YELLOW_LIGHTEST: "#fffe72",
  MJH_PURPLE_DARK: "#1f0c19",
  MJH_PURPLE_LIGHT: "#2a1825",
  MJH_PURPLE_LIGHTEST: "#362431",
  MJH_PURPLE_LIGHTEST2: "#251821",

  // NVIDIA
  NVIDIA_GREEN: "#95d600",
  NVIDIA_GRAY: "#1e1e1e",

  // VZONE
  VZONE_ACCENT: "#ff4454",
  VZONE_ACCENT_LIGHT: "#ffd9dd",
  VZONE_DARK: "#0b1118",
  VZONE_DARK_LIGHT: "#101822",
  VZONE_LIGHT: "#a7a7a7",
  VZONE_LIGHTEST: "#e4e4e4",

  // WORDPRESS EDITOR COLORS
  WP_CYAN_BLUISH_GRAY: "#abb8c3",
  WP_WHITE: "#fff",
  WP_PALE_PINK: "#f78da7",
  WP_VIVID_RED: "#cf2e2e",
  WP_LUMINOUS_VIVID_ORANGE: "#ff6900",
  WP_LUMINOUS_VIVID_AMBER: "#fcb900",
  WP_LIGHT_GREEN_CYAN: "#7bdcb5",
  WP_VIVID_GREEN_CYAN: "#00d084",
  WP_PALE_CYAN_BLUE: "#8ed1fc",
  WP_VIVID_CYAN_BLUE: "#0693e3",
  WP_VIVID_PURPLE: "#9b51e0",
};
