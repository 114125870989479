import Link from "next/link";
import styled from "styled-components";

import { S3_BASE_URL } from "../../config/constants";

export function BannerCTA() {
  return (
    <Link prefetch={false} href={"/major-blasttv-23"} passHref>
      <Container>
        <img
          src={`${S3_BASE_URL}/assets/major-hub/blasttv23/logo_BlastTv23.png`}
        />

        <span>
          Notícias, horários e resultados do{" "}
          <strong>BLAST.tv Paris Major 2023</strong> em nosso hub
        </span>

        <div>Clique aqui</div>
      </Container>
    </Link>
  );
}

const Container = styled.a`
  height: 2.5rem;
  width: 100%;
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  background: linear-gradient(90deg, #362431 0%, #1f0c19 100%);

  span {
    font-size: 0.75rem;

    color: #ffffff;

    margin: 0 1rem;
  }

  div {
    height: 2.5rem;
    padding: 0 0.5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 0.75rem;
    font-weight: bold;

    background-color: #ffff2e;
    color: #171c21;
  }

  @media screen and (max-width: 768px) {
    span,
    div {
      font-size: 1rem;
    }
  }
`;
