import React, { Component } from "react";
import styled from "styled-components";
import Icon from "./Icon";
import { colors } from "../../config/styles";

export default class Button extends Component {
  render() {
    const {
      text,
      icon,
      theme,
      size,
      block,
      disabled,
      isLoading,
      fontSize,
      showIconRight,
    } = this.props;

    return (
      <ButtonContainer
        {...this.props}
        theme={theme}
        disabled={disabled || isLoading}
        size={size}
        block={block}
      >
        {!isLoading && (
          <>
            {icon && !showIconRight && (
              <ButtonIcon
                size={fontSize || 18}
                text={text}
                name={icon}
                fontSize={fontSize}
              />
            )}
            {text}
            {icon && showIconRight && (
              <ButtonIcon
                size={fontSize || 18}
                text={text}
                name={icon}
                fontSize={fontSize}
                style={{ marginLeft: "8px" }}
              />
            )}
          </>
        )}

        {isLoading && (
          <>
            <ButtonIcon
              size={fontSize || 18}
              text={text}
              name="spinner"
              spin
              fontSize={fontSize}
            />
            {text}
          </>
        )}
      </ButtonContainer>
    );
  }
}

const ButtonContainer = styled.a`
  width: ${(props) => (props.block ? "100%" : "auto")};
  border: 0;
  border-radius: 2px;
  padding: 16px 16px;
  color: white;
  font-weight: bold;
  font-size: ${(props) => props.fontSize || 18}px;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
  transition: all 0.2s;
  box-shadow: 0 0px 35px rgba(50, 50, 93, 0.01), 0 5px 15px rgba(0, 0, 0, 0.07);
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &:hover {
    box-shadow: 0 0px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.2);
  }

  ${(props) => props.theme}
  ${(props) => props.size}

  @media screen and (max-width: 768px) {
    ${(props) => props.buttonResponsive}
  }
`;

export const ButtonIcon = styled(Icon)`
  display: inline-block;
  margin-right: ${(props) => (props.text ? "8px" : "0px")};
  height: ${(props) => (props.fontSize ? props.fontSize + 4 : 20)}px;
  width: ${(props) => (props.fontSize ? props.fontSize + 4 : 20)}px;
  font-size: ${(props) => (props.fontSize ? props.fontSize + 4 : 20)}px;
`;

export const buttonThemes = {
  default: {
    MAIN: `
      background: ${colors.MAIN};
      color: white;
      fill: white;
      border-color: ${colors.MAIN};
    `,
    ACCENT: `
      background: ${colors.ACCENT};
      color: white;
      fill: white;
      border-color: ${colors.ACCENT};
    `,
    ERROR: `
      background: ${colors.ERROR};
      color: white;
      fill: white;
      border-color: ${colors.ERROR};
    `,
    SUCCESS: `
      background: ${colors.SUCCESS};
      color: white;
      fill: white;
      border-color: ${colors.SUCCESS};
    `,
    WHITE: `
      background: white;
      color: ${colors.GRAY_DARK};
      fill: ${colors.GRAY_DARK};
      border-color: white;
    `,
    WHATSAPP: `
      background: ${colors.WHATSAPP};
      color: white;
      fill: white;
      border-color: ${colors.WHATSAPP};
      border-radius: 20px;
      margin: 0 0 0 3px;
      
      @media screen and (max-width: 768px) {
        margin-left: 0;
        border-radius: 0px;
        margin-top: 2px;
      }
    `,
    GNEWS: `
      background: ${colors.ACCENT};
      color: white;
      fill: white;
      border-color: ${colors.ACCENT};
      border-radius: 20px;
      margin: 0 3px 0 0;
      
      @media screen and (max-width: 768px) {
        margin-right: 0;
        border-radius: 0px;
        margin-bottom: 2px;
      }
    `,
  },
  outline: {
    MAIN: `
      background: transparent;
      border: 1px solid ${colors.MAIN};
      color: ${colors.MAIN};
      fill: ${colors.MAIN};
    `,
    WHITE: `
      background: transparent;
      border: 1px solid white;
      color: white;
      fill: white;
    `,
    ACCENT: `
      background: transparent;
      color: ${colors.ACCENT};
      border: 1px solid ${colors.ACCENT};
      fill: ${colors.ACCENT};
    `,
    ERROR: `
      background: transparent;
      color: ${colors.ERROR};
      border: 1px solid ${colors.ERROR};
      fill: ${colors.ERROR};
    `,
    SUCCESS: `
      background: transparent;
      color: ${colors.SUCCESS};
      border: 1px solid ${colors.SUCCESS};
      fill: ${colors.SUCCESS};
    `,
  },
};

export const buttonSizes = {
  MEDIUM: `
    font-size: 14px;
    padding: 10px;
  `,
  SMALL: `
    font-size: 14px;
    padding: 8px;
  `,
  TINY: `
    font-size: 12px;
    padding: 8px;
  `,
};

export const buttonResponsive = {
  MEDIUM: `
    font-size: 20px;
    padding: 16px;
  `,
  SMALL: `
    font-size: 16px;
    padding: 12px;
  `,
  TINY: `
    font-size: 12px;
    padding: 12px;
  `,
};
