import React from "react";

const draft5 = (props) => {
  return (
    <svg {...props} data-name="Camada 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1168.47 298.58">
      <g>
        <g>
          <path className="cls-1" d="M270.91,130.13c0,16.26-1.84,31.66-5.53,46.21-3.69,14.55-8.95,28.08-15.78,40.58-6.83,12.5-15.13,23.77-24.9,33.81-9.77,10.04-20.7,18.62-32.79,25.72-12.09,7.11-25.28,12.57-39.55,16.39-14.28,3.83-29.27,5.74-44.98,5.74H0L36.89,0h107.38c19.53,0,37.12,3.31,52.77,9.94,15.64,6.63,28.93,15.75,39.86,27.36,10.93,11.61,19.33,25.34,25.21,41.19,5.87,15.85,8.81,33.06,8.81,51.64Zm-68.45,1.64c0-12.16-1.4-23.19-4.2-33.1-2.8-9.9-6.97-18.34-12.5-25.31-5.53-6.97-12.33-12.36-20.39-16.19-8.06-3.82-17.28-5.74-27.66-5.74h-40.17l-23.77,195.7h40.17c13.66,0,25.96-2.69,36.89-8.09,10.93-5.4,20.22-13.08,27.87-23.05,7.65-9.97,13.52-22.06,17.62-36.27,4.1-14.21,6.15-30.19,6.15-47.95Z" />
          <path className="cls-1" d="M313.57,187.1l-13.73,111.48h-66.4L269.92,0h90.58c18.31,0,33.92,1.95,46.83,5.84,12.91,3.89,23.46,9.26,31.66,16.09,8.2,6.83,14.17,14.93,17.93,24.28,3.76,9.36,5.64,19.5,5.64,30.43s-1.47,21.52-4.41,31.35c-2.94,9.84-7.21,18.89-12.81,27.15-5.6,8.27-12.47,15.58-20.59,21.93-8.13,6.35-17.39,11.58-27.77,15.68,4.1,2.32,7.96,5.12,11.58,8.4,3.62,3.28,6.46,7.38,8.5,12.29l43.44,105.13h-60.25c-5.6,0-10.32-1.09-14.14-3.28-3.83-2.18-6.49-5.33-7.99-9.43l-32.38-88.32c-1.5-3.82-3.52-6.52-6.04-8.1-2.53-1.57-6.25-2.36-11.17-2.36h-14.96Zm17.01-137.3l-11.27,91.19h24.39c9.56,0,17.69-1.47,24.39-4.41,6.69-2.94,12.12-6.86,16.29-11.78,4.17-4.92,7.17-10.66,9.02-17.21,1.84-6.56,2.77-13.45,2.77-20.7,0-5.6-.82-10.69-2.46-15.27-1.64-4.58-4.2-8.47-7.68-11.68-3.48-3.21-7.86-5.7-13.11-7.48-5.26-1.77-11.44-2.66-18.55-2.66h-23.77Z" />
          <path className="cls-1" d="M679.95,298.58h-51.64c-5.74,0-10.35-1.33-13.83-4-3.48-2.66-5.57-6.18-6.25-10.55l-9.22-53.69h-101.44l-22.13,53.69c-1.51,3.82-4.44,7.21-8.81,10.14-4.37,2.94-9.22,4.41-14.55,4.41h-52.46L542.24,0h68.45l69.27,298.58Zm-163.74-113.73h75l-14.55-85.25c-1.23-7.1-2.56-14.31-4-21.62-1.43-7.31-2.63-13.97-3.59-19.98-1.09,3.28-2.53,7-4.3,11.17-1.78,4.17-3.52,8.23-5.23,12.19-1.71,3.96-3.35,7.65-4.92,11.07-1.57,3.42-2.7,5.94-3.38,7.58l-35.04,84.84Z" />
          <path className="cls-1" d="M654.01,274.01L687.87,0h177.86l-6.56,51.44h-110.44l-9.83,78.69h92.4l-6.97,51.44h-91.79l-14.35,117.01h-44.01l-20.17-24.57Z" />
          <path className="cls-1" d="M1021.39,0l-6.98,52.35h-75.98l-30.4,246.24h-66.54l29.98-247.19h-76.39L801.65,0h219.74Z" />
          <path className="cls-1" d="M1165.22,25.14c-.54,4.05-1.66,7.8-3.34,11.25-1.69,3.45-4.12,6.42-7.3,8.92-3.18,2.5-7.13,4.43-11.85,5.78-4.73,1.35-10.34,2.03-16.83,2.03h-72.16l-15.2,52.09c5.54-1.08,10.88-1.82,16.01-2.23,5.14-.41,10.07-.61,14.8-.61,14.46,0,27.13,2.03,38.01,6.08,10.88,4.05,19.93,9.63,27.16,16.72,7.23,7.09,12.67,15.44,16.32,25.03,3.65,9.6,5.47,19.93,5.47,31.01,0,17.16-3.24,32.94-9.73,47.33-6.49,14.39-15.41,26.76-26.76,37.09-11.35,10.34-24.7,18.41-40.03,24.22-15.34,5.81-31.86,8.72-49.56,8.72-9.6,0-18.62-.98-27.06-2.94-8.45-1.96-16.22-4.63-23.31-8.01-7.1-3.38-13.58-7.3-19.46-11.76-5.88-4.46-11.11-9.19-15.71-14.19l21.89-25.34c2.3-2.7,4.87-4.66,7.71-5.88,2.83-1.22,5.67-1.83,8.51-1.83,3.92,0,7.53,1.08,10.85,3.24,3.31,2.16,6.99,4.56,11.05,7.19,4.05,2.63,8.85,5.04,14.39,7.2,5.54,2.16,12.5,3.24,20.88,3.24,9.59,0,17.87-1.69,24.83-5.07,6.96-3.37,12.67-7.84,17.13-13.38,4.46-5.54,7.77-11.96,9.93-19.26,2.16-7.3,3.24-14.93,3.24-22.91,0-11.89-3.65-21.32-10.95-28.28-7.3-6.96-18.31-10.44-33.04-10.44-5.68,0-11.83,.54-18.45,1.62-6.62,1.08-13.78,2.84-21.49,5.27l-36.08-10.13L1018.47,0h150l-3.24,25.14Z" />
        </g>
      </g>
    </svg>
  );
};

export default draft5;
