import React from "react";

const VZone = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
      <path d="M8,65v43h8.8h8.7l5-6.3c2.8-3.4,14.4-17.8,25.8-32L77.1,44H66.3H55.5l-15,18.7l-15,18.6l-0.3-29.6L25,22h-8.5H8V65z" />
      <path d="M95.1,22H37l6.2,8.5l6.3,8.4L65.2,39H81l6.2-7.6" />
      <path d="M121,22h-20.7L65.9,64.7l-34.5,42.8l22.3,0.3c12.3,0.1,32.4,0.1,44.8,0l22.5-0.3l-6-7.5l-6-7.5L87.4,92l-21.6-0.5l27.6-34.2" />
    </svg>
  );
};

export default VZone;
