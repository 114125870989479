import { Fragment } from "react";

const HeadScripts = () => (
  <Fragment>
    <script type='text/javascript' dangerouslySetInnerHTML={{
      __html: `     
      (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:649015,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      `
    }} />

    <script
      data-ad-client="ca-pub-1267769760525634"
      async
      src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
    ></script>
    <script
      dangerouslySetInnerHTML={{
        __html: `
      window.fbAsyncInit = function() {
        FB.init({
          appId      : '281369788996885',
          xfbml      : true,
          version    : 'v2.9'
        });
        FB.AppEvents.logPageView();
      };

      (function(d, s, id){
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {return;}
        js = d.createElement(s); js.id = id;
        js.src = "//connect.facebook.net/pt_BR/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
  `,
      }}
    />
    <script src="https://embed.twitch.tv/embed/v1.js" />
    <script type='text/javascript' dangerouslySetInnerHTML={{
      __html: `     
        window.smartlook||(function(d) {
          var o=smartlook=function(){o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
            var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
            c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
          })(document);
          
        smartlook('init', 'ca2f0a209b1751a9e30faa63653e5a48fd78e3f6', {region: 'eu' });
      `
    }} />
  </Fragment>
);

export default HeadScripts;
