import React from "react";

const Plus = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" version="1.1">
      <path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
    </svg>
  );
};

export default Plus;
