import React from "react";

const Users = (props) => {
  return (
    <svg {...props} width="12" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path d="M6.5 2.75C6.96413 2.75 7.40925 2.93437 7.73744 3.26256C8.06563 3.59075 8.25 4.03587 8.25 4.5C8.25 4.96413 8.06563 5.40925 7.73744 5.73744C7.40925 6.06563 6.96413 6.25 6.5 6.25C6.03587 6.25 5.59075 6.06563 5.26256 5.73744C4.93437 5.40925 4.75 4.96413 4.75 4.5C4.75 4.03587 4.93437 3.59075 5.26256 3.26256C5.59075 2.93437 6.03587 2.75 6.5 2.75ZM3 4C3.28 4 3.54 4.075 3.765 4.21C3.69 4.925 3.9 5.635 4.33 6.19C4.08 6.67 3.58 7 3 7C2.60218 7 2.22064 6.84196 1.93934 6.56066C1.65804 6.27936 1.5 5.89782 1.5 5.5C1.5 5.10218 1.65804 4.72064 1.93934 4.43934C2.22064 4.15804 2.60218 4 3 4ZM10 4C10.3978 4 10.7794 4.15804 11.0607 4.43934C11.342 4.72064 11.5 5.10218 11.5 5.5C11.5 5.89782 11.342 6.27936 11.0607 6.56066C10.7794 6.84196 10.3978 7 10 7C9.42 7 8.92 6.67 8.67 6.19C9.10594 5.62721 9.30828 4.9181 9.235 4.21C9.46 4.075 9.72 4 10 4ZM3.25 9.125C3.25 8.09 4.705 7.25 6.5 7.25C8.295 7.25 9.75 8.09 9.75 9.125V10H3.25V9.125ZM0.5 10V9.25C0.5 8.555 1.445 7.97 2.725 7.8C2.43 8.14 2.25 8.61 2.25 9.125V10H0.5ZM12.5 10H10.75V9.125C10.75 8.61 10.57 8.14 10.275 7.8C11.555 7.97 12.5 8.555 12.5 9.25V10Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_292_3156">
          <rect width="12" height="12" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Users;

