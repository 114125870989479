import React, { Component } from "react";
import AdsService from "../services/ads";

const AdContext = React.createContext();

export default class AdContextProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      spots: [],
    };
  }

  async componentDidMount() {
    try {
      const response = await AdsService.getSpots();
      this.setState({
        spots: response.data.data,
      });
    } catch (error) {
      console.error("Failed to load spots:", error);
    }
  }

  render() {
    return (
      <AdContext.Provider value={this.state}>
        {this.props.children}
      </AdContext.Provider>
    );
  }
}

const AdContextConsumer = AdContext.Consumer;
export { AdContext, AdContextConsumer };
