import React from "react";

const CircleArrowUp = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M0.333322 7.33334C0.333322 5.56522 1.0357 3.86953 2.28594 2.61929C3.53619 1.36905 5.23188 0.666669 6.99999 0.666669C7.87547 0.666668 8.74237 0.839106 9.55121 1.17414C10.36 1.50917 11.095 2.00023 11.714 2.61929C12.9643 3.86953 13.6667 5.56522 13.6667 7.33333C13.6667 9.10144 12.9643 10.7971 11.714 12.0474C10.4638 13.2976 8.7681 14 6.99999 14C6.12451 14 5.2576 13.8276 4.44877 13.4925C3.63993 13.1575 2.905 12.6664 2.28594 12.0474C1.66689 11.4283 1.17582 10.6934 0.840791 9.88456C0.50576 9.07572 0.333322 8.20881 0.333322 7.33334ZM1.66666 7.33334C1.66666 8.74782 2.22856 10.1044 3.22875 11.1046C4.22895 12.1048 5.5855 12.6667 6.99999 12.6667C8.41448 12.6667 9.77103 12.1048 10.7712 11.1046C11.7714 10.1044 12.3333 8.74782 12.3333 7.33333C12.3333 5.91885 11.7714 4.56229 10.7712 3.5621C9.77103 2.5619 8.41448 2 6.99999 2C5.5855 2 4.22895 2.5619 3.22875 3.5621C2.22856 4.56229 1.66666 5.91885 1.66666 7.33334ZM11 8.66667L6.99999 4.66667L2.99999 8.66667L3.93332 9.6L6.99999 6.53333L10.0667 9.6L11 8.66667Z" fill="#545D65" />
    </svg>
  );
};

export default CircleArrowUp;
